import * as codes from '@/const/codes.ts'

export default {
  mutationOptions (state, payload) {
    // 公司類型
    if (payload.data_type) {
      state.dataTypeOptions = payload.data_type.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }

    // 1.狀態
    if (payload.company_state) {
      state.companyStateOptions = payload.company_state.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 營業狀態
    if (payload.company_operate) {
      state.companyOperateOptions = payload.company_operate.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 行業別
    if (payload.etax_matters) {
      state.etaxMattersOptions = payload.etax_matters.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d,
          _etaxCode: d.key ? d.key.substr(0, 2) : '' // key的前兩碼，為母選單的對應代碼        
        }
      })
    }
    // 2.所在地區
    if (payload.company_county) {
      let companyCountyOptions = []
      let companyCountySubOptions = {}
      payload.company_county.forEach(d => {
        companyCountyOptions.push({
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        })
        if (!companyCountySubOptions[d.key]) {
          companyCountySubOptions[d.key] = []
        }
        if (d.sub) {
          d.sub.forEach(sub => {
            companyCountySubOptions[d.key].push({
              label: `${d.key}-${sub.key}(${sub.doc_count})`,
              value: JSON.stringify(sub),
              source: sub
            })
          })
        }
      })
      state.companyCountyOptions = companyCountyOptions
      state.companyCountySubOptions = companyCountySubOptions
    }
    // 3.成立年限
    if (payload.date_approved) {
      state.dateApprovedOptions = payload.date_approved.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 4.資本總額
    if (payload.capital_amount) {
      state.capitalAmountOptions = payload.capital_amount.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 5.員工人數
    if (payload.employee_count) {
      state.employeeCountOptions = payload.employee_count.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 6.股權狀況
    if (payload.stock_state) {
      state.stockStateOptions = payload.stock_state.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 7.公開發行
    // 8.最近一年度總進口實績
    if (payload.imports) {
      state.importsOptions = payload.imports.map(d => {
        const data = codes.IMPORT_AND_EXPORT_DATA[d.key]
        let dataLabel = d.key
        if (data) {
          dataLabel = data.label
        }
        return {
          label: `${dataLabel}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 9.最近一年度總出口實績
    if (payload.exports) {
      state.exportsOptions = payload.exports.map(d => {
        const data = codes.IMPORT_AND_EXPORT_DATA[d.key]
        let dataLabel = d.key
        if (data) {
          dataLabel = data.label
        }
        return {
          label: `${dataLabel}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }
    // 10.最近一個月得標金額
    if (payload.procurement_history) {
      state.procurementHistoryOptions = payload.procurement_history.map(d => {
        return {
          label: `${d.key}(${d.doc_count})`,
          value: JSON.stringify(d),
          source: d
        }
      })
    }

    // if (payload.company_operate) {
    //   state.companyOperateOptions = payload.company_operate.map(d => {
    //     return {
    //       label: `${d.key}(${d.doc_count})`,
    //       value: JSON.stringify(d),
    //       source: d
    //     }
    //   })
    // }
    
  },
  // mutationBatchDataType (state, payload) {
  //   state.batchDataType = payload
  // },
  mutationBatchDataList (state, payload) {
    state.batchDataList = payload
    // .map((d, index) => {
    //   return {
    //     index,
    //     // selected: d.查詢結果筆數 > 0 ? true : false,
    //     ...d
    //   }
    // })
  },
  mutationSelectedBatchRows (state, payload) {
    state.selectedBatchRows = payload
  },
  
}
