import * as config from '@/const/config.js'
import * as codes from '@/const/codes.ts'
import moment from 'moment'
import dateApprovedSliderOptions from '@/const/dateApprovedSliderOptions.js'
import capitalAmountSliderOptions from '@/const/capitalAmountSliderOptions.js'
import importsAndExportsSliderOptions from '@/const/importsAndExportsSliderOptions.js'
import procurementHistorySliderOptions from '@/const/procurementHistorySliderOptions.js'
const currentYear = moment().year()

export default {
  // // 紀錄首頁的查詢條件
  // mutationOriginSearchQuery (state, payload) {
  //   state.originSearchQuery = payload
  // },
  // 紀錄目前的查詢條件（含篩選）
  mutationCurrentSearchQuery (state, payload) {
    state.currentSearchQuery = payload
  },
  // 紀錄目前的篩選條件字串（比對用）
  mutationCurrentFilterQueryString (state, payload) {
    state.currentFilterQueryString = payload
  },
  mutationIsPageLoading (state, payload) {
    state.isPageLoading = payload
  },
  mutationIsCompanyListLoading (state, payload) {
    state.isCompanyListLoading = payload
  },
  // mutationSidebarMenu (state, payload) {
  //   state.sidebarMenu = payload
  // },
  mutationInitDataTypeArray (state, payload) {
    state.initDataTypeArray = payload
  },
  mutationInitClsDataType (state, payload) {
    state.initClsDataType = payload
  },
  // 公司列表
  mutationCompanyList (state, payload) {
    state.companyList = payload
  },
  // 公司總數
  mutationCompanyCount (state, payload) {
    state.companyCount = payload

    if (payload > config.SEARCH_MAX_COUNT) {
      payload = config.SEARCH_MAX_COUNT
    }
    state.companySearchableCount = payload
  },
  mutationIsCollections (state, payload) {
    state.isCollections = payload
  },
  // 後分類選單
  mutationOptions (state, payload) {
    if (payload.data_type) {
      state.dataTypeOptions = payload.data_type.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
    } else {
      state.dataTypeOptions = []
    }
    // if (payload.company_operate) {
    //   state.companyOperateOptions = payload.company_operate.map(d => {
    //     let children = null
    //     if (d.sub) {
    //       children = d.sub.map(subData => {
    //         return {
    //           id: subData.key,
    //           label: subData.key,
    //           source: subData
    //         }
    //       })
    //     }
    //     return {
    //       id: d.key,
    //       label: d.key,
    //       children,
    //       source: d
    //     }
    //   })
    // }
    
    // 1.公司狀態
    if (payload.company_state) {
      state.companyStateOptions = payload.company_state.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
    } else {
      state.companyStateOptions = []
    }
    // 營業狀態
    if (payload.company_operate) {
      state.companyOperateOptions = payload.company_operate.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
    } else {
      state.companyOperateOptions = []
    }
    // 2.所在地區
    if (payload.company_county) {
      state.companyCountyOptions = payload.company_county.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
    } else {
      state.companyCountyOptions = []
    }
    // 3.成立年限
    if (payload.date_approved && payload.date_approved.length) {
      state.dateApprovedOptions = payload.date_approved.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
      // slider選單
      let min = 0
      let max = Infinity
      let from = payload.date_approved[0].from_as_string
      let to = payload.date_approved[payload.date_approved.length - 1].to_as_string
      if (from) {
        const year = Number(from.slice(0, 4))
        max = currentYear - year
      }
      if (to) {
        const year = Number(to.slice(0, 4))
        min = currentYear - year
      }
      state.dateApprovedSliderOptions = JSON.parse(JSON.stringify(dateApprovedSliderOptions)).filter(d => {
        return d.value >= min && d.value <= max
      })
    } else {
      state.dateApprovedOptions = []
      state.dateApprovedSliderOptions = []
    }
    // 4.資本總額
    if (payload.capital_amount && payload.capital_amount.length) {
      state.capitalAmountOptions = payload.capital_amount.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
      // slider選單
      const min = payload.capital_amount[0].from || 0
      const max = payload.capital_amount[payload.capital_amount.length - 1].to || Infinity
      state.capitalAmountSliderOptions = JSON.parse(JSON.stringify(capitalAmountSliderOptions)).filter(d => {
        return d.value >= min && d.value <= max
      })
    } else {
      state.capitalAmountOptions = []
      state.capitalAmountSliderOptions = []
    }
    // 5.員工人數
    // 6.股權狀況
    if (payload.stock_state) {
      state.stockStateOptions = payload.stock_state.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
    } else {
      state.stockStateOptions = []
    }
    // 7.公開發行
    if (payload.public_issue) {
      state.publicIssueOptions = payload.public_issue.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
    } else {
      state.publicIssueOptions = []
    }
    // 8.最近一年度總進口實績
    if (payload['imports-last-year'] && payload['imports-last-year'].length) {
      state.importsOptions = payload['imports-last-year'].map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
      // slider選單
      let min = Infinity
      let max = 0
      payload['imports-last-year'].forEach(d => {
        const item = codes.IMPORT_AND_EXPORT_DATA[d.key]
        if (d.key && item) {
          if (item.max < min) { // 因為對照表裡的value和min都有重覆的所以用max來比
            min = item.max
          }
          if (item.max > max) {
            max = item.max
          }
        }
      })
      state.importsSliderOptions = JSON.parse(JSON.stringify(importsAndExportsSliderOptions)).filter(d => {
        return d.value >= min && d.value <= max
      })
    } else {
      state.importsOptions = []
      state.importsSliderOptions = []
    }
    // 9.最近一年度總出口實績
    if (payload['exports-last-year'] && payload['exports-last-year'].length) {
      state.exportsOptions = payload['exports-last-year'].map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
      // slider選單
      let min = Infinity
      let max = 0
      payload['exports-last-year'].forEach(d => {
        const item = codes.IMPORT_AND_EXPORT_DATA[d.key]
        if (d.key && item) {
          if (item.max < min) { // 因為對照表裡的value和min都有重覆的所以用max來比
            min = item.max
          }
          if (item.max > max) {
            max = item.max
          }
        }
      })
      state.exportsSliderOptions = JSON.parse(JSON.stringify(importsAndExportsSliderOptions)).filter(d => {
        return d.value >= min && d.value <= max
      })
    } else {
      state.exportsOptions = []
      state.exportsSliderOptions = []
    }
    // 10.最近一個月得標金額
    if (payload.procurement_history && payload.procurement_history.length) {
      state.procurementHistoryOptions = payload.procurement_history.map(d => {
        let children = null
        if (d.sub) {
          children = d.sub.map(subData => {
            return {
              id: subData.key,
              label: subData.key,
              source: subData
            }
          })
        }
        return {
          id: d.key,
          label: d.key,
          children,
          source: d
        }
      })
      // slider選單
      const min = payload.procurement_history[0].from || 0
      const max = payload.procurement_history[payload.procurement_history.length - 1].to || Infinity
      state.procurementHistorySliderOptions = JSON.parse(JSON.stringify(procurementHistorySliderOptions)).filter(d => {
        return d.value >= min && d.value <= max
      })
    } else {
      state.procurementHistoryOptions = []
      state.procurementHistorySliderOptions = []
    }
  }
}
