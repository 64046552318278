import * as apis from '@/apis/index.ts'

export default {
  async actionInitSearchPage ({ dispatch, commit, state, rootState }, searchRequest) {
    commit('mutationIsPageLoading', true)
    commit('mutationCompanyList', [])
    commit('mutationCompanyCount', 0)
    commit('mutationOptions', [])

    // -- 將首頁設定的查詢條件紀錄起來 --
    commit('mutationCurrentSearchQuery', searchRequest)

    // -- 查詢 dataType選單 --
    const originQuery = JSON.parse(rootState.originRouteQuery.originQuery) // 取得初始查詢條件
    originQuery.basic.get = ['cls']
    commit('mutationInitDataTypeArray', originQuery.basic.data_type_array || [])
    const dataTypeRequest = apis.companyListGet(originQuery)
      .then((data) => {
        commit('mutationInitClsDataType', data.payload.cls || {})
        return true
      })
      .catch(e => {
        return false
      })

    // -- 查詢 公司列表查詢 --
    const companyListResult = apis.companyListGet(searchRequest)
      .then((data) => {
        commit('mutationCompanyList', data.payload.list || [])
        commit('mutationCompanyCount', data.payload.stat['total-count'])
        // commit('mutationInitDataTypeArray', searchRequest.basic.data_type_array || [])
        // commit('mutationInitClsDataType', data.payload.cls || {})
        commit('mutationOptions', data.payload.cls || {})
        return true
      })
      .catch(e => {
        return false
      })

    const result = await Promise.all([dataTypeRequest, companyListResult])
      .then(data => data[0] && data[1])
      .catch(e => false)

    commit('mutationIsPageLoading', false)
    // 是否為收藏查詢（不等）
    const uniIDs = state.companyList.map(d => d.uniID)
    const isCollectionsRequest = {
      "email": rootState.userInfo.email,
      "companys": uniIDs
    }
    const isCollectionsResult = apis.isCollections(isCollectionsRequest)
      .then((data) => {
        commit('mutationIsCollections', data.payload || {})
      })

    // const result = await Promise.all([companyListResult, isCollectionsResult])
    //   .then(data => true)
    //   .catch(e => false)
    
    return result
  },
  // 列表&後分類查詢
  async actionSearchPage ({ dispatch, commit, state }, searchRequest) {

    // 決定要查詢的內容
    let get = ['list', 'cls']
    // if (filterQueryString === state.currentFilterQueryString) {
    //   get = ['list'] // 如篩選條件和上次相同，則不查詢後分類
    // }
    searchRequest.basic.get = get

    // 紀錄目前查詢條件
    commit('mutationCurrentSearchQuery', searchRequest)

    commit('mutationIsPageLoading', true)

    // 開始查詢
    let result = await apis.companyListGet(searchRequest)
      .then((data) => {
        commit('mutationCompanyList', data.payload.list || [])
        commit('mutationCompanyCount', data.payload.stat['total-count'])
        commit('mutationOptions', data.payload.cls || [])
        return true
      })
      .catch(e => {
        return false
      })
    commit('mutationIsPageLoading', false)
    // commit('mutationIsCompanyListLoading', false)
    return result
  },
}
