import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'

export default {
  // 批次搜尋檔案名稱
  getteBatchFileName (state, getters, rootState, rootGetters) {
    return state.currentSearchQuery.basic['search-label'] && /(.csv|.xls|.xlsx)/.test(state.currentSearchQuery.basic['search-label'].label)
      ? state.currentSearchQuery.basic['search-label'].label
      : ''
  },
  // 搜尋條件文字描述
  getterSearchConditionList (state, getters, rootState, rootGetters) {
    let textArr = []
    if (state.currentSearchQuery) {
      // -- 基本資料組字串 --
      // 說明：basic內不事先組好文字的原因，是因為基本查詢會有很多不同的頁面送出request，在這個地方做比較容易（只需做一次）
      if (state.currentSearchQuery.basic) {
        if (getters.getteBatchFileName) {
          // 批次搜尋
          textArr.push(`上傳檔案：${getters.getteBatchFileName}`)
        } else if (state.currentSearchQuery.basic.keyword) {
          // keyword
          textArr.push(`關鍵字：${state.currentSearchQuery.basic.keyword}`)
        }
        // // 關鍵字類型：
        // if (state.currentSearchQuery.basic.keyword_type) {
        //   textArr.push(`關鍵字類型：${codes.COMPANY_KEYWORD_TYPE_DICT[state.currentSearchQuery.basic.keyword_type]}`)
        // }
        // // 公司類型
        // if (state.currentSearchQuery.basic.data_type) {
        //   textArr.push(`公司類型：${state.currentSearchQuery.basic.data_type}`)
        // }
        if (state.currentSearchQuery.basic._keyword_search_fields) {
          // 使用 _keyword_search_fields 取得原始勾選的選項
          textArr.push(`公司登記狀態：${state.currentSearchQuery.basic._keyword_search_fields.join('、')}`)
        }
        if (state.currentSearchQuery.basic.data_type_array) {
          textArr.push(`搜尋類別：${state.currentSearchQuery.basic.data_type_array.join('、')}`)
        }
        // -- 查詢頁的進階設定 --
        // let advTextArr = []
        // Object.keys(state.currentSearchQuery.basic).forEach(d => {
        //   if (codes.FILTER_ITEM_DICT[d]) {
        //     const text = `${codes.FILTER_ITEM_DICT[d]}(${state.currentSearchQuery.basic[d]})`
        //     advTextArr.push(text)
        //   }
        // })
        // if (advTextArr.length) {
        //   const text = advTextArr.join('、')
        //   textArr.push(`進階設定：${text}`)
        // }
      }
      // -- 篩選條件（含查詢頁的進階設定） --
      if (state.currentSearchQuery.filter && state.currentSearchQuery.filter.length) {
        state.currentSearchQuery.filter.forEach(item => {
          if (item._desc) {
            textArr.push(`篩選條件：${item._desc}`)
          }
        })
      }
    }
    return textArr
  },
  getterSidebarMenu (state, getters, rootState, rootGetters) {
    const sidebarMenu = [
      {
        label: '全部',
        dataTypeIndex: 'default',
        doc_count: 0
      },
      {
        label: '公司',
        dataTypeIndex: 'company',
        doc_count: 0
      },
      {
        label: '商業登記',
        dataTypeIndex: 'business',
        doc_count: 0
      },
    ]
    if (state.initClsDataType.data_type) {
      const 公司 = state.initClsDataType.data_type.find(d => d.key === '公司')
      if (公司) {
        sidebarMenu[1].doc_count = 公司.doc_count
      }
      const 商業登記 = state.initClsDataType.data_type.find(d => d.key === '商業')
      if (商業登記) {
        sidebarMenu[2].doc_count = 商業登記.doc_count
      }
      sidebarMenu[0].doc_count = sidebarMenu[1].doc_count + sidebarMenu[2].doc_count
    }

    if (state.initDataTypeArray.length === 2) {
      return sidebarMenu
    } else if (state.initDataTypeArray[0] === '公司') {
      return [
        sidebarMenu[1]
      ]
    } else {
      return [
        sidebarMenu[2]
      ]
    }
  }
}
