export default {
  // originSearchQuery: null,
  currentSearchQuery: null, // 目前的查詢條件
  // currentFilterQueryString: null,
  isPageLoading: false,
  isCompanyListLoading: false,
  // sidebarMenu: [],
  companyList: [],
  companyCount: 0,
  companySearchableCount: 0,
  isCollections: {}, // 加入收藏
  // companyListQuery: {}
  initDataTypeArray: [], // 初次查詢的公司資料類型（選單用）
  initClsDataType: [], // 初次查詢的公司登記狀態資訊（選單用）
  // "keywordSearchFields": ["公司名稱"],
  // "dataTypeArray": ["公司"],

  // -- 後分類選單 --
  dataTypeOptions: [],
  // companyOperateOptions: [],
  companyStateOptions: [],
  companyOperateOptions: [],
  companyCountyOptions: [],
  dateApprovedOptions: [],
  dateApprovedSliderOptions: [],
  capitalAmountOptions: [],
  capitalAmountSliderOptions: [],
  stockStateOptions: [],
  publicIssueOptions: [],
  importsOptions: [],
  importsSliderOptions: [],
  exportsOptions: [],
  exportsSliderOptions: [],
  procurementHistoryOptions: [],
  procurementHistorySliderOptions: []
}
