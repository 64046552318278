import * as apis from '@/apis/index.ts'

export default {
  // 取得查詢選單
  async actionOptions ({ dispatch, commit, state }, payload) {
    const searchRequest = {
      basic: {
        get: ['cls'],
        ignore_keyword: 1
      }
    }
    const result = await apis.companyListGet(searchRequest)
      .then((data) => {
        commit('mutationOptions', data.payload.cls)
        return true
      })
      .catch(e => {
        return false
      })
    return result
  },
  async actionBatchSearch ({ dispatch, commit, state }, xlsxRows) {
    const result = await apis.companyListGet({
      basic: {
        "get": [
            "xlsx-rows-simplified" // 表示取得 xlsx 精簡過的結果, 
            // 取得的每個結果只會有公司名稱與統編, 
            // 並且每行最多顯示 3 筆找到的公司(參考spec)
        ],
        "xlsx-rows": xlsxRows
      }

    })

    // result.payload['xlsx-rows-simplified'][0].查詢結果筆數 = 0 // @Q@ test
    commit('mutationBatchDataList', result.payload['xlsx-rows-simplified'])

  }
}
